@if (link === undefined || link.length === 0) {
  <div class="side-nav-item" [ngClass]="{'closed': ((navService.sideNavCollapsed$ | async)), 'active': highlighted}">
    <ng-container [ngTemplateOutlet]="inner"></ng-container>
  </div>
} @else {
  @if (external) {
    <a [id]="id" class="side-nav-item" [href]="link" [ngClass]="{'closed': ((navService.sideNavCollapsed$ | async)), 'active': highlighted}" rel="noopener noreferrer" target="_blank">
      <ng-container [ngTemplateOutlet]="inner"></ng-container>
    </a>
  } @else {
    @if (queryParams && queryParams !== {}) {
      <a [id]="id" class="side-nav-item" href="javascript:void(0);" [ngClass]="{'closed': ((navService.sideNavCollapsed$ | async)), 'active': highlighted}" (click)="openLink()">
        <ng-container [ngTemplateOutlet]="inner"></ng-container>
      </a>
    } @else if (fragment) {
      <a [id]="id" class="side-nav-item" href="javascript:void(0);" [ngClass]="{'closed': ((navService.sideNavCollapsed$ | async)), 'active': highlighted}" [routerLink]="link" [fragment]="fragment">
        <ng-container [ngTemplateOutlet]="inner"></ng-container>
      </a>
    } @else {
      <a [id]="id" class="side-nav-item" href="javascript:void(0);" [ngClass]="{'closed': ((navService.sideNavCollapsed$ | async)), 'active': highlighted}" [routerLink]="link" [queryParams]="queryParams">
        <ng-container [ngTemplateOutlet]="inner"></ng-container>
      </a>
    }
  }
}

<ng-template #inner>
    <div class="active-highlight"></div>
    @if (!noIcon) {
      <span class="phone-hidden" title="{{title}}">
        <div>
          @if (imageUrl !== null && imageUrl !== '') {
            <app-image [imageUrl]="imageUrl" width="20px" height="20px"></app-image>
          } @else {
            <i class="fa {{icon}}" aria-hidden="true"></i>
          }
        </div>
      </span>
    }

    <span class="side-nav-text">
        <div>
          {{title}}
          @if (badgeCount && badgeCount > 0) {
            <span class="badge bg-danger rounded-pill ms-2">{{badgeCount}}</span>
          }
        </div>
    </span>
    <span class="card-actions">
        <ng-content select="[actions]"></ng-content>
    </span>
</ng-template>
